import styled from 'styled-components';
import { Panel } from 'components/layouts';

const LinkWrapperStyled = styled.div`
    width: 100%;
    height: 100%;
`;

const PanelStyled = styled(Panel)`
    margin-bottom: ${({ theme }) => theme.spacing['8']};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumDesktop}px) {
        margin-bottom: ${({ theme }) => theme.spacing['12']};
    }
`;

export { LinkWrapperStyled, PanelStyled };

import styled from 'styled-components';
import { Span } from 'typography';

const LinkStyled = styled.a`
    width: 100%;
    overflow: hidden;
`;

const ImageContainerStyled = styled.div`
    padding-top: 120%; // Keep the aspect ratio
    contain: strict;
    position: relative;
    width: 100%;
    border-radius: 5px;

    @media (min-width: ${({ theme }) => `${theme.screenSize.mediumTablet}px`}) {
        padding-top: 136px;
    }
`;

const ImageWrapperStyled = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`;

const ImageStyled = styled.div`
    html.no-js & {
        background-image: ${({ image }) => `url(${image})`};
    }
    background-image: ${({ image, inView }) => (inView ? `url(${image})` : '')};
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
`;

const ImageOverlayStyled = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, ${({ theme }) => theme.opacityOverlay.dark.active.strength});
`;

const SuggestionTitleStyled = styled(Span)`
    color: ${({ theme }) => theme.palette.white};
    font-size: ${({ theme }) => theme.fontSizes.medLarge};
    line-height: 1.2;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-align: center;
    padding: 0 ${({ theme }) => theme.spacing[2]};
    white-space: normal;

    @media (min-width: ${({ theme }) => `${theme.screenSize.mediumTablet}px`}) {
        padding: 0 ${({ theme }) => theme.spacing[3.5]};
    }
`;

export {
    LinkStyled,
    ImageContainerStyled,
    ImageWrapperStyled,
    ImageStyled,
    ImageOverlayStyled,
    SuggestionTitleStyled,
};

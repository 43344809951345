const PER_PAGE = {
    mobile: 1.5,
    largeHandset: 2.2,
    mediumTablet: 3,
    mediumDesktop: 4,
    largeDesktop: 4,
    wide: 4,
    extraWide: 4,
};

// eslint-disable-next-line import/prefer-default-export
export { PER_PAGE };

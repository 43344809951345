import React from 'react';
import { useInView } from 'react-intersection-observer';

import {
    LinkStyled,
    ImageContainerStyled,
    ImageWrapperStyled,
    ImageStyled,
    ImageOverlayStyled,
    SuggestionTitleStyled,
} from './LinksCarouselCard.style';

const LinksCarouselCard = ({ title, link, image }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <LinkStyled ref={ref} href={link}>
            <ImageContainerStyled>
                <ImageWrapperStyled>
                    <ImageStyled inView={inView} image={image}>
                        <ImageOverlayStyled>
                            <SuggestionTitleStyled>{title}</SuggestionTitleStyled>
                        </ImageOverlayStyled>
                    </ImageStyled>
                </ImageWrapperStyled>
            </ImageContainerStyled>
        </LinkStyled>
    );
};

LinksCarouselCard.defaultProps = {};

export default LinksCarouselCard;

import React from 'react';

import Carousel from 'components/Carousel';

import LinksCarouselCard from '../LinksCarouselCard';
import { LinkWrapperStyled, PanelStyled } from './LinksCarousel.style';
import { PER_PAGE } from './LinksCarousel.constants';

const LinksCarousel = ({ compact, links, perPage, ...props }) => {
    /**
     * Callback for on item click
     * @param index - index of the item clicked
     */
    const onItemClick = (index) => {
        if (props.onItemClick) {
            props.onItemClick(index);
        }
    };

    return (
        <PanelStyled>
            <Carousel perPage={perPage}>
                {links.map((link, index) => {
                    const key = `links-${index}`;
                    return (
                        <LinkWrapperStyled
                            key={key}
                            compact={compact}
                            onClick={() => onItemClick(index)}
                        >
                            <LinksCarouselCard
                                link={link.url}
                                title={link.title}
                                image={link.image.url}
                            />
                        </LinkWrapperStyled>
                    );
                })}
            </Carousel>
        </PanelStyled>
    );
};

/**
 * Default to no results
 */
LinksCarousel.defaultProps = {
    links: [],
    perPage: PER_PAGE,
};

export default LinksCarousel;
